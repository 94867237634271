import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import { Button, CircularProgress } from "@mui/material";
import Cards from "react-credit-cards";
import "react-credit-cards/es/styles-compiled.css";
import InputMask from "react-input-mask";
import { config } from "config";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
export interface CheckOutPageProps {
  className?: string;
}

const PaymentForm = () => {
  const navigate = useNavigate();
  const [basket_items, setBasketItems] = React.useState<any["id"]>([]);
  const [can_pay, setCanPay] = React.useState(true);
  const [price, setPrice] = React.useState(0);
  React.useEffect(() => {
    try {
      let items_str: any = localStorage.getItem("basket_tickets");
      let items = JSON.parse(items_str);
      setBasketItems(items);
      setTimeout(() => {
        basket_items &&
          basket_items.map((item: any, index: number) => {
            setPrice(price + parseFloat(item.fiyat));
          });
          if(price <= 0){
            setCanPay(false);
          }
      }, 250);
    } catch (e) {
      navigate("/");
    }
  }, []);
  const [is_checkout_disabled, setIsCheckoutDisabled] = React.useState(false);
  const [is_checkout_loading, setIsCheckoutLoading] = React.useState(false);
  const [state, setState] = React.useState({
    adsoyad: "",
    tcno: "",
    phone: "",
    adres: "",
    email: "",
    mesaj: "",
    cvc: "",
    expiry: "",
    focus: "",
    name: "",
    number: "",
  });
  const handleInputFocus = (e: any) => {
    setState({ ...state, focus: e.target.name });
  };
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setState({ ...state, [name]: value });
  };
  const makeCheckout = () => {
    let post_data = JSON.stringify({
      adsoyad: state.adsoyad,
      adres: state.adres,
      tcno: state.tcno,
      phone: state.phone,
      mesaj: state.mesaj,
      email: state.email,
      card_holder: state.name,
      card_no: state.number,
      card_expiry: state.expiry,
      card_cvc: state.cvc,
      tickets: localStorage.getItem("basket_tickets"),
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: post_data,
    };
    setIsCheckoutLoading(true);
    setIsCheckoutDisabled(true);
    
    try{
      fetch(`${config.apiUrl}${config.checkoutPostUrl}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.code < 500) {
          toast(data.message, {
            duration: 4000,
            position: "top-center",
            style: { fontSize: 14 },
            className: "",
            icon: data.code == 100 ? "✅" : "⚠️",
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            ariaProps: {
              role: "status",
              "aria-live": "polite",
            },
          });
        } else {
          if (data.errors !== undefined) {
            let errors_string = "";
            Object.keys(data.errors).forEach((key) => {
              errors_string += data.errors[key][0] + "\n";
            });
            toast(errors_string, {
              duration: 8000,
              position: "top-center",
              style: { fontSize: 14 },
              className: "",
              icon: "❌",
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          } else {
            toast(data.message, {
              duration: 8000,
              position: "top-center",
              style: { fontSize: 14 },
              className: "",
              icon: "❌",
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          }
        }
        setIsCheckoutLoading(false);
        setIsCheckoutDisabled(false);
        // If payment successful
        if (data.code === 100) {
          localStorage.setItem("basket_tickets", "");
          localStorage.setItem("event_detail", "");
          localStorage.setItem("pay-id", data.pay_id);
          setTimeout(() => {
            navigate("/pay-done");
          }, 4000);
        }
      });
    }catch(exception){
      toast("Bilinmeyen bir sorun oluştu. Ödeme işlemi başlatılamadı.", {
        duration: 8000,
        position: "top-center",
        style: { fontSize: 14 },
        className: "",
        icon: "❌",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
    }
  };

  return (
    <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
      <h2 className="text-3xl lg:text-4xl font-semibold">Ödeme</h2>
      <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
      <div>
        <div>
          <h3 className="text-2xl font-semibold mb-8">Bilgiler</h3>
          <div className="flex space-x-5 mb-2">
            <div className="flex-1 space-y-1">
              <Label>Adınız & Soyadınız</Label>
              <input
                name="adsoyad"
                type="text"
                onChange={handleInputChange}
                defaultValue={state.adsoyad}
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>TC Kimlik Numaranız</Label>
              <InputMask
                mask="99999999999"
                defaultValue={state.tcno}
                name="tcno"
                onChange={handleInputChange}
                className={`mb-2 block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
              />
            </div>
          </div>
          <div className="flex space-x-5 mb-2">
            <div className="flex-1 space-y-1">
              <Label>Telefon Numaranız</Label>
              <InputMask
                mask="0599 999 99 99"
                defaultValue={state.phone}
                name="phone"
                onChange={handleInputChange}
                className={`mb-2 block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
              />
            </div>
            <div className="flex-1 space-y-1">
              <Label>E-posta</Label>
              <input
                name="email"
                type="email"
                defaultValue={state.email}
                onChange={handleInputChange}
                className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
              />
            </div>
          </div>
          <div className="flex-1 space-y-1">
            <Label>Adres</Label>
            <input
              name="adres"
              id="adres"
              type="text"
              defaultValue={state.adres}
              onChange={handleInputChange}
              className={`block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
            />
          </div>
          <div className="space-y-1">
            <Label>Mesajınız </Label>
            <Textarea
              placeholder="Mesajınızı yazın"
              name="mesaj"
              defaultValue={state.mesaj}
              onChange={handleInputChange}
            />
            <span className="text-sm text-neutral-500 block">
              İletmek istediğiniz mesajı yukarıya yazabilirsiniz.
            </span>
          </div>
        </div>
      </div>
      {can_pay &&
      <div>
        <h3 className="text-2xl font-semibold">Kart Bilgileri</h3>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
        <div className="mt-6">
          <div id="PaymentForm" className="flex items-center flex-col-reverse">
            <Cards
              cvc={state.cvc}
              expiry={state.expiry}
              name={state.name}
              number={state.number}
              placeholders={{ name: "AD & SOYAD" }}
            />
            <form className="w-full">
              <div className="flex space-x-5 mb-2">
                <div className="flex-1 space-y-1">
                  <Label>Kart Numarası </Label>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    defaultValue={state.number}
                    name="number"
                    onChange={handleInputChange}
                    className={`mb-2 block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
                  />
                  <span className="text-xs text-neutral-500 block">
                    Kartınızın ön yüzünde bulunan 16 haneli numara
                  </span>
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Kart Sahibi </Label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Kart Sahibi"
                    className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl mb-2"
                    defaultValue={state.name}
                    onChange={handleInputChange}
                    onFocus={handleInputFocus}
                  />
                  <span className="text-xs text-neutral-500 block">
                    Kartınızın ön yüzünde bulunan ad & soyad
                  </span>
                </div>
              </div>
              <div className="flex space-x-5 mb-2">
                <div className="flex-1 space-y-1">
                  <Label>CVV Kodu </Label>
                  <InputMask
                    mask="999"
                    defaultValue={state.cvc}
                    name="cvc"
                    onChange={handleInputChange}
                    className={`mb-2 block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
                  />
                  <span className="text-xs text-neutral-500 block">
                    Kartınızın arka yüzünde bulunan 3 haneli güvenlik kodu
                  </span>
                </div>
                <div className="flex-1 space-y-1">
                  <Label>Son Kullanım Tarihi </Label>
                  <InputMask
                    mask="99/99"
                    defaultValue={state.expiry}
                    name="expiry"
                    onChange={handleInputChange}
                    className={`block w-full border border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 h-11 px-4 py-3 text-sm font-normal rounded-2xl`}
                  />
                  <span className="text-xs text-neutral-500 block">
                    Kartınızın son kullanım tarihi AY/YIL şeklinde
                  </span>
                </div>
              </div>
            </form>
          </div>
          <div className="pt-8 flex align-center justify-center w-full">
            <Button
              className="btn-default primary"
              disabled={is_checkout_disabled}
              onClick={makeCheckout}
            >
              {is_checkout_loading && (
                <CircularProgress color="primary" className="mr-4" size={20} />
              )}
              KART İLE ÖDE
            </Button>
          </div>
        </div>
      </div>
      }
      {!can_pay &&
        <Button
        className="btn-default primary"
        disabled={is_checkout_disabled}
        onClick={makeCheckout}
      >
        {is_checkout_loading && (
          <CircularProgress color="primary" className="mr-4" size={20} />
        )}
        ÜCRETSİZ SATIN AL
      </Button>
      }
    </div>
  );
};
const CheckOutPage: FC<CheckOutPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [basket_items, setBasketItems] = React.useState<any["id"]>([]);
  const [event_detail, setEventDetail] = React.useState<any["id"]>();
  const [can_pay, setCanPay] = React.useState(true);

  React.useEffect(() => {
    try {
      let items_str: any = localStorage.getItem("basket_tickets");
      let event_detail_str: any = localStorage.getItem("event_detail");
      let items = JSON.parse(items_str);
      let event_detail_items = JSON.parse(event_detail_str);
      setBasketItems(items);
      setEventDetail(event_detail_items);
    } catch (e) {
      setCanPay(false);
      toast("Sepetinizde satın alabileceğiniz ürün bulunmuyor", {
        duration: 8000,
        position: "top-center",
        style: { fontSize: 14 },
        className: "",
        icon: "❌",
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
        ariaProps: {
          role: "status",
          "aria-live": "polite",
        },
      });
      navigate("/");
    }
  }, []);
  let price = 0;

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <div className="flex flex-col space-y-4">
          <h3 className="text-2xl font-semibold">Sepetiniz</h3>
          <span className="text-md text-neutral-500 block mb-8">
            {event_detail?.title} adlı etkinliğin &nbsp;
            {moment(event_detail?.date).format("DD.MM.YYYY")}
            &nbsp; tarihindeki {event_detail?.seans_title} seansı için
          </span>
          {basket_items &&
            basket_items
              .sort((a: any, b: any) => (a.koltuk > b.koltuk ? 1 : -1))
              .map((item: any, index: number) => {
                price += parseFloat(item.fiyat);
                return (
                  <div
                    className="flex items-center justify-between text-neutral-6000 dark:text-neutral-300"
                    key={`basket-item-${index}`}
                  >
                    <span>
                      {item.seans_saat} seansı - {item.sira}
                      {item.koltuk} no'lu koltuk
                    </span>
                    <span>{item.fiyat} TL</span>
                  </div>
                );
              })}
          <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex justify-between font-semibold">
            <span>Toplam</span>
            <span>{price.toFixed(2)} TL</span>
          </div>
        </div>
      </div>
    );
  };

  const renderMain = () => {
    return <PaymentForm />;
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <Toaster />
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
          {renderMain()}
          {/* {can_pay && } */}
        </div>
        <div className="block lg:block flex-grow mb-24">
          {can_pay && renderSidebar()}
        </div>
      </main>
    </div>
  );
};

export default CheckOutPage;
