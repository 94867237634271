import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import EventDetailPage from "containers/EventsPage/EventDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import PageSorgu from "containers/PageSorgu/PageSorgu";
import SiteHeader from "containers/SiteHeader";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import KVKKPage from "containers/DocumentsPage/KVKKPage";
import SatisPage from "containers/DocumentsPage/SatisPage";
import GizlilikPage from "containers/DocumentsPage/GizlilikPage";
import CategoriesPage from "containers/EventsPage/CategoriesPage";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import EventsPage from "containers/EventsPage/EventsPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/#", exact: true, component: PageHome },
  { path: "/etkinlik-kategori/:id", component: ListingStayPage },
  { path: "/tum-etkinlikler", component: EventsPage },
  { path: "/etkinlik/:id", component: EventDetailPage },
  { path: "/etkinlik-kategorileri", component: CategoriesPage },
  { path: "/checkout", component: CheckOutPage },
  { path: "/pay-done", component: PayPage },
  { path: "/kvkk", component: KVKKPage },
  { path: "/satis-sozlesmesi", component: SatisPage },
  { path: "/gizlilik-beyani", component: GizlilikPage },
  { path: "/bilet-sorgula", component: PageSorgu },
  //
];

const MyRoutes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  return (
    <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />

      <Routes>
        {pages.map(({ component, path }) => {
          const Component = component;
          return <Route key={path} element={<Component />} path={path} />;
        })}
        <Route element={<Page404 />} />
      </Routes>

      {WIN_WIDTH < 768 && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
