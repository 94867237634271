import React, { FC } from "react";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link, useNavigate } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { CircularProgress } from "@mui/material";
import { config } from "config";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";
export interface PageSorguProps {
  className?: string;
}

const PageSorgu: FC<PageSorguProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [ticket_detail, setTicketDetail] = React.useState<any["code"]>([]);
  const [state, setState] = React.useState({
    code: "",
  });
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;

    setState({ ...state, [name]: value });
  };
  const checkTicket = () => {
    setLoading(true);
    let post_data = JSON.stringify({
      code: state.code,
    });
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: post_data,
    };
    fetch(`${config.apiUrl}${config.getTicketDetail}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data.message !== undefined) {
          if (data.code < 500) {
            setLoading(false);
            toast(data.message, {
              duration: 4000,
              position: "top-center",
              style: { fontSize: 14 },
              className: "",
              icon: data.code == 100 ? "✅" : "⚠️",
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          } else {
            setLoading(false);
            toast(data.message, {
              duration: 8000,
              position: "top-center",
              style: { fontSize: 14 },
              className: "",
              icon: "❌",
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              ariaProps: {
                role: "status",
                "aria-live": "polite",
              },
            });
          }
        } else {
          if (data !== undefined) {
            setTicketDetail(data);
            setLoading(false);
          }
        }
      });
  };
  const cancelTicket = () => {
    confirmAlert({
      title: "Dikkat, ",
      message:
        "Bu bilet iptal edilecek. Geri ödeme birkaç iş günü içerisinde yapılacaktır. Devam etmek istiyor musunuz ?",
      buttons: [
        {
          label: "Evet",
          onClick: () => {
            setLoading(true);
            let post_data = JSON.stringify({
              code: state.code,
            });
            const requestOptions = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
              },
              body: post_data,
            };
            fetch(`${config.apiUrl}${config.cancelTicketUrl}`, requestOptions)
              .then((response) => response.json())
              .then((data) => {
                if (data.message !== undefined) {
                  if (data.code < 500) {
                    setLoading(false);
                    toast(data.message, {
                      duration: 4000,
                      position: "top-center",
                      style: { fontSize: 14 },
                      className: "",
                      icon: data.code == 100 ? "✅" : "⚠️",
                      iconTheme: {
                        primary: "#000",
                        secondary: "#fff",
                      },
                      ariaProps: {
                        role: "status",
                        "aria-live": "polite",
                      },
                    });
                  } else {
                    setLoading(false);
                    toast(data.message, {
                      duration: 8000,
                      position: "top-center",
                      style: { fontSize: 14 },
                      className: "",
                      icon: "❌",
                      iconTheme: {
                        primary: "#000",
                        secondary: "#fff",
                      },
                      ariaProps: {
                        role: "status",
                        "aria-live": "polite",
                      },
                    });
                  }
                } else {
                  if (data !== undefined) {
                    setTicketDetail(data);
                    setLoading(false);
                  }
                }
              });
          },
        },
        {
          label: "Hayır",
          onClick: () => alert("Click No"),
        },
      ],
    });
  };
  return (
    <div className={`nc-PageSorgu ${className}`} data-nc-id="PageSorgu">
      <Toaster />
      <Helmet>
        <title>Bilet Sorgula</title>
      </Helmet>
      <div className="container mb-12 lg:mb-32">
        {!ticket_detail ||
          (ticket_detail.length < 1 && (
            <>
              <h2 className="my-10 flex ticket_detail-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                Bilet Sorgula
              </h2>
              <div className="max-w-md mx-auto space-y-6">
                <div className="relative text-center">
                  <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900"></span>
                  <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
                </div>
                <div className="grid grid-cols-1 gap-6">
                  <label className="block">
                    <span className="text-neutral-800 dark:text-neutral-200">
                      Bilet Kodunuz
                    </span>
                    <Input
                      type="text"
                      placeholder="Bilet kodunuzu yazın"
                      className="mt-1"
                      value={state.code}
                      name="code"
                      onChange={handleInputChange}
                    />
                  </label>
                  <ButtonPrimary type="button" onClick={checkTicket}>
                    {loading && (
                      <CircularProgress
                        size={24}
                        style={{ color: "#ffffff" }}
                        className="mr-2"
                      />
                    )}
                    {!loading && "Sorgula"}
                  </ButtonPrimary>
                </div>
              </div>
            </>
          ))}
        {ticket_detail && ticket_detail.length === undefined && (
          <div className="max-w-xxl mx-auto space-y-6 mt-12">
            <div className="overflow-hidden bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Bilet Detayları
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Sorguladığınız bilet koduna ait bilet detayları.
                </p>
              </div>
              <div className="border-t border-gray-200">
                <dl>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Ad & Soyad
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {ticket_detail.name}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                    Bilet Kodu
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {ticket_detail.code}
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Oluşturulma Tarihi
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      {moment(ticket_detail.created_at).format(
                        "DD.MM.YYYY - HH:mm"
                      )}
                    </dd>
                  </div>
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Etkinlik Bilgileri
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <div className="bg-white px-3 py-2 sm:grid sm:grid-cols-1 sm:gap-2 sm:px-3 rounded-md border">
                        Etkinlik: {ticket_detail.event?.title}
                        <p>
                          Tarih:{" "}
                          {moment(ticket_detail.event?.date).format(
                            "DD.MM.YYYY"
                          )}
                        </p>
                        <p>Seans: {ticket_detail.seans?.time}</p>
                        <p>Adres: {ticket_detail.event?.adres}</p>
                        {/* <p>photo: {ticket_detail.event?.photo}</p>
                      <p>id: {ticket_detail.event?.id}</p> */}
                      </div>
                    </dd>
                  </div>
                  <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt className="text-sm font-medium text-gray-500">
                      Satın Alımlar
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                      <ul
                        role="list"
                        className="divide-y divide-gray-200 rounded-md border border-gray-200"
                      >
                        {ticket_detail?.orders?.map((item: any, index: any) => (
                          <li
                            className="flex ticket_detail-center justify-between py-3 pl-3 pr-4 text-sm"
                            key={`koltuk-list-${index}`}
                          >
                            <div className="flex w-0 flex-1 ticket_detail-center">
                              <span className="ml-2 w-0 flex-1 truncate">
                                {item.sira_bilgi} Koltuğu
                                {/* {item.mesaj && (
                                  <>
                                    <br />{" "}
                                    <span className="text-xs">
                                      Not: {item.mesaj}
                                    </span>
                                  </>
                                )} */}
                              </span>
                            </div>
                            <div className="ml-4 flex-shrink-0">
                              <a
                                href="#"
                                className="font-medium text-indigo-600 hover:text-indigo-500"
                              >
                                {item.fiyat} TL
                              </a>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </dd>
                  </div>
                  {ticket_detail?.has_cancel && (
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        Durum
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <div
                          className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                          role="alert"
                        >
                          <span className="font-medium">Bilet iptal edildi!</span> Biletiniz iptal edildi ve artık geçerli değil.
                        </div>
                      </dd>
                    </div>
                  )}
                  {ticket_detail?.can_cancel && (
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500">
                        İptal
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                        <button
                          type="button"
                          onClick={cancelTicket}
                          className="text-white bg-red-700 hover:bg-red-800 focus:outline-none focus:ring-4 focus:ring-red-300 font-medium rounded-full text-sm px-5 py-2.5 text-center mr-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                        >
                          {loading && (
                            <div className="selector-progress">
                              <CircularProgress size={24} />
                            </div>
                          )}
                          Bileti İptal Et
                        </button>
                      </dd>
                    </div>
                  )}
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSorgu;
