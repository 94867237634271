import { Skeleton } from "@mui/material";
import Heading from "components/Heading/Heading";
import { config } from "config";
import React from "react";
import { Link } from "react-router-dom";
import Badge from "shared/Badge/Badge";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";

export interface SectionGridCategoryBoxProps {
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}
const SectionGridCategoryBox: React.FC<SectionGridCategoryBoxProps> = ({
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  const [categories, setCategories] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchCategories = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getCategoriesUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setCategories(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  React.useEffect(() => {
    fetchCategories();
  }, []);
  return (
    <div className={`nc-SectionGridCategoryBox relative ${className}`}>
      {!loading && categories && categories.length > 0 && (
        <>
          <Heading
            desc="İlgini çeken bir kategoriye gözat"
            isCenter={headingCenter}
          >
            Kategorileri Keşfet
          </Heading>
          <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
            {categories.map((item, i) => (
              <Link
                to={item.href}
                className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
                data-nc-id="CardCategoryBox1"
                key={`category-item-${i}`}
              >
                <Badge
                  className="absolute right-2 top-2"
                  color="gray"
                  name={convertNumbThousand(item.event_count)}
                />

                <div className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden">
                  <NcImage
                    src={`${config.appUrl}/upload/etkinlikler/${item.photo}`}
                    containerClassName="absolute inset-0"
                  />
                </div>
                <div className="ml-4 flex-grow overflow-hidden">
                  <h2 className="text-base font-medium">
                    <span className="line-clamp-1">{item.name}</span>
                  </h2>
                  {/* <span
                    className={`block mt-2 text-sm text-neutral-500 dark:text-neutral-400`}
                  >
                    19 minutes drive
                  </span> */}
                </div>
              </Link>
            ))}
          </div>
        </>
      )}
      {loading && <Skeleton variant="rectangular" width={'100%'} height={90} style={{width:'100%'}}/>}
    </div>
  );
};

export default SectionGridCategoryBox;
