import React, { FC, ReactNode, useEffect } from "react";
import { EventsType } from "data/types";
import Heading from "components/Heading/Heading";
import HomeEventCard from "components/HomeEventCard/HomeEventCard";
import { config } from "config";
import ButtonPrimary from "shared/Button/ButtonPrimary";

// OTHER DEMO WILL PASS PROPS
/* const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8); */
//
export interface SectionGridFeaturePropertyProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
}

const SectionGridFeatureProperty: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = "",
  heading = "Etkinliklerimiz",
  subHeading = "Diğer etkinliklerimize göz atın",
}) => {
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchItems = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.homeExploreEventsUrl)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  useEffect(() => {
    fetchItems();
  }, []);
  const renderCard = (stay: EventsType, index: number) => {
    return <HomeEventCard key={index} className="h-full" data={stay} />;
  };

  return (
    <div className="nc-SectionGridFeatureProperty relative">
      <Heading desc={subHeading} isCenter={true}>
        {heading}
      </Heading>
      <div
        className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
      >
        {items && items.map(renderCard)}
      </div>
      <div className="p-3" />
      <div className="seeAllEvents flex items-center justify-center">
        <ButtonPrimary href="/tum-etkinlikler">Tümünü Gör</ButtonPrimary>
      </div>
    </div>
  );
};

export default SectionGridFeatureProperty;
