import React, { FC, ReactNode, useEffect } from "react";
import { EventsType } from "data/types";
import Heading from "components/Heading/Heading";
import HomeEventCard from "components/HomeEventCard/HomeEventCard";
import { config } from "config";

export interface SectionGridFeaturePropertyProps {
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
}

const AllEventsPage: FC<SectionGridFeaturePropertyProps> = ({
  gridClass = "",
  heading = "Tüm Etkinliklerimiz",
  subHeading = "",
}) => {
  const [items, setItems] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(true);
  const fetchItems = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getAllEvents)
      .then((res) => res.json())
      .then(
        (result) => {
          setItems(result);
          setLoading(false);
        },
        (error) => {}
      );
  };
  useEffect(() => {
    fetchItems();
  }, []);
  const renderCard = (stay: EventsType, index: number) => {
    return <HomeEventCard key={index} className="h-full" data={stay} />;
  };

  return (
    <div className="container relative overflow-hidden py-10">
      <div className="nc-SectionGridFeatureProperty relative">
        <Heading desc={subHeading} isCenter={true}>
          {heading}
        </Heading>
        <div
          className={`grid gap-6 md:gap-8 grid-cols-1 sm:grid-cols-1 xl:grid-cols-2 ${gridClass}`}
        >
          {items && items.map(renderCard)}
        </div>
      </div>
    </div>
  );
};

export default AllEventsPage;
