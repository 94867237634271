import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { config } from "config";
import NcImage from "shared/NcImage/NcImage";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

export interface PayPageProps {
  className?: string;
}

const PayPage: FC<PayPageProps> = ({ className = "" }) => {
  const navigate = useNavigate();
  const [data, setData] = React.useState<any['name']>([]);
  const [loading, setLoading] = React.useState(true);
  
  let post_data = JSON.stringify({
    pay_id: localStorage.getItem("pay-id"),
  });
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: post_data,
  };
  const fetchData = async () => {
    setLoading(true);
    fetch(config.apiUrl + config.getPayDetail, requestOptions)
      .then((res) => res.json())
      .then(
        (result) => {
          setData(result);
          setLoading(false);
          localStorage.setItem("pay-id","")
        },
        (error) => {}
      );
  };
  React.useEffect(() => {
    if(localStorage.getItem("pay-id") == ""){
      navigate("/");
    }
    fetchData();
  }, []);
  const renderContent = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold text-center">
          Ödemeniz Gerçekleştirildi
        </h2>

        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        {loading && (
          <div className="flex justify-center align-center">
            <CircularProgress size={32}/>
          </div>
        )}
        <div className="border border-t border-gray-200">
          <dl>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Ad & Soyad</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {data.ticket?.name}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Bilet Kodu</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {data.ticket?.code}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Oluşturulma Tarihi
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                {moment(data.created_at).format("DD.MM.YYYY - HH:mm")}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Etkinlik Bilgileri
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <div className="bg-white px-3 py-2 sm:grid sm:grid-cols-1 sm:gap-2 sm:px-3 rounded-md border">
                  Etkinlik: {data.ticket?.event?.title}
                  <p>
                    Tarih:{" "}
                    {moment(data.ticket?.event?.date).format("DD.MM.YYYY")}
                  </p>
                  <p>Seans: {data.ticket?.seans_title}</p>
                  <p>Adres: {data.ticket?.event?.adres}</p>
                </div>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">
                Satın Alımlar
              </dt>
              <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul
                  role="list"
                  className="divide-y divide-gray-200 rounded-md border border-gray-200"
                >
                  {data?.orders?.map((item: any, index:any) => (
                    <li className="flex data-center justify-between py-3 pl-3 pr-4 text-sm" key={`list-item-${index}`}>
                      <div className="flex w-0 flex-1 data-center">
                        <span className="ml-2 w-0 flex-1 truncate">
                          {item.sira_bilgi} Koltuğu
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <a
                          href="#"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          {item.fiyat} TL
                        </a>
                      </div>
                    </li>
                  ))}
                </ul>
              </dd>
            </div>
          </dl>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-PayPage ${className}`} data-nc-id="PayPage">
      <main className="container mt-11 mb-24 lg:mb-32 ">
        <div className="max-w-4xl mx-auto">{renderContent()}</div>
      </main>
    </div>
  );
};

export default PayPage;
